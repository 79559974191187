import * as React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../img/logo-white.svg';

export function Footer({ activePage, getTranslation }) {

  return (
    <>

      <footer className="footer">
        <div className="container-default">
          <div className="w-layout-grid footer-grid">
            <div className="footer-column">
              <Link to="/" className="footer-logo-container mg-bottom-24px w-inline-block">
                <img src={logo} id="logoFooter" alt="" className="footer-logo" width="200" />
              </Link>
              <div className="social-links-wrapper">
              </div>
            </div>
            <div className="footer-column middle">
              <div className="footer-nav-main-wrapper">
                <div id="footerTitle" className="footer-title">Menu</div>
                <div className="footer-nav-wrapper">
                  <ul className="footer-nav">
                    <li className="footer-nav-item">
                      <Link to="/terms-and-conditions" id="footerTnC" className="footer-link">
                        {getTranslation("footerTnC")}
                      </Link>
                    </li>
                    <li className="footer-nav-item">
                      <Link to="/contact" id="footerContact" className="footer-link">
                        {getTranslation("footerContact")}
                      </Link>
                      <Link to="/faq" className="footer-link">
                        FAQ
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="divider bg-neutral-600"></div>
          <div className="footer-bottom-content">
            <div id="copyrightText">Copyright © 2022-2023 Open Racing. All Rights Reserved</div>
          </div>
        </div>
        <div className="defisentrybadge">
          <a href="https://defisentry.com/" target="_blank" rel="noreferrer" className="w-inline-block">
            <p id="certifiedByText">Certified by</p>
            <img src="images/certifiedByDefiSentry.png" loading="lazy" alt="" className="image-10" />
          </a>
        </div>
      </footer>
    </>
  )
}

