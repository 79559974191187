import * as React from 'react';
import { useNetwork, useSwitchNetwork } from 'wagmi';

export function ChangeNetwork({ validChain }) {

  const { chain } = useNetwork();
  const { chains, error, isLoading, pendingChainId, switchNetwork } = useSwitchNetwork();

  let isValidChain = chain.id === validChain.id;

  function switchNetworkClick() {
    if (validChain) {
      switchNetwork(validChain.id);
    }
  }

  return (
    <>
      <section aria-label="Ethos Login Box" className="Ethos-login">
        <div className="em-loginbox">
          <h1>Switch network</h1>
          <p className="ethosHeroExp text-clickToConnect">Avalanche is the only supported network. Make sure to switch your wallet to AVAX C-chain</p>
          <div className="em-btnsect">
            <button className="dr-btn2 cursor-pointer" onClick={switchNetworkClick} disabled={isValidChain}>Switch Network</button>
          </div>
        </div>
      </section>
    </>
  )
}

