import { avalanche, avalancheFuji } from 'wagmi/chains';

import raceableNFT from './ABI/raceableNFT.json';
import helperABI from './ABI/helperContract.json';
import raceABI from './ABI/race.json';
import supportedCollections from './ABI/supportedCollections.json';

//all addresses in the confid file should be lowercase - in some scenarios, the frontend looks for matches within these arrays, using an address that's converted to lowercase

export const ADDRESSES = {
  [avalanche.id]: {
    collections: "0xcb338596f14547735084e64a90c1ea144d44b994",
    race: "0xA42f33433f64afD46c613f19136dEaDD05e9b2c0",
    raceHelper: "0x4125fcaef8cfa36F4f1E9D0d7E71e80DFBA7Fa48",
    doge: "0xc31191730f21b9c336904ab93d6bbc582df54ebb",
    cyberlete: "0xfE78D395f341E1516479ecfe0eC8B19770f703C4",
    disc: "0x8868d09ABe1b1B7CA64ec5fe84a0939610Bb2651",
    smolFrogz: "0xDd55BC4D5353CF15a633561812f66F4599D289DE"
  },
  [avalancheFuji.id]: {
    collections: "0x58467849ff6e8d1601c00f6030c2ad722fef344f",
    race: "0x86BE4e0349AEFdca13D2B713c063cfD846918702",
    raceHelper: "0x5F451170889B1C7cD0661E6b8FAEB3E5507be329",
    doge: "0x524b9a279ddfd56c962b7ebfcd7062d907c66b18",
    cyberlete: "0x16124e1e2ee33a8271f276a7b18bfb650d0c1e43",
    disc: "0x50b471550d2d40611f2710408bb08512019a4121",
    smolFrogz: "0x12fc5e1126d3d18ca10446556dd0fa514dff2864"
  }
};

export const ABIS = {
  race: raceABI,
  doge: raceableNFT,
  raceHelper: helperABI,
  collections: supportedCollections
};

export const APIS = {
  "0xfe78d395f341e1516479ecfe0ec8b19770f703c4": "https://api.ethosmetaverse.net/cyberletebulk/",
  "0x8868d09abe1b1b7ca64ec5fe84a0939610bb2651": "https://api.ethosmetaverse.net/discbulk/",
  "0xc31191730f21b9c336904ab93d6bbc582df54ebb": "https://api.ethosmetaverse.net/moondogebulk/",
  "0xdd55bc4d5353cf15a633561812f66f4599d289de": "https://api.ethosmetaverse.net/smolfrogzbulk/"
};
/*
export const COLLECTIONS = {
  doge: "0x524b9a279ddfd56c962b7ebfcd7062d907c66b18",
  cyberlete: "0x16124e1e2ee33a8271f276a7b18bfb650d0c1e43",
  disc: "0x50b471550d2d40611f2710408bb08512019a4121",
  smolFrogz: "0x12fc5e1126d3d18ca10446556dd0fa514dff2864"
};
*/
export const COLLECTIONS = {
  doge: "0xc31191730f21b9c336904ab93d6bbc582df54ebb",
  cyberlete: "0xfe78d395f341e1516479ecfe0ec8b19770f703c4",
  disc: "0x8868d09abe1b1b7ca64ec5fe84a0939610bb2651",
  smolFrogz: "0xdd55bc4d5353cf15a633561812f66f4599d289de"
};

export const NETWORK = avalanche;
export const INTERVAL = 30000;
export const ALLOWED_FEES_COUNT = 7;