import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Web3Button } from "@web3modal/react";
import { useCookies } from 'react-cookie';

import flagEN from '../../img/en-flag.png';
import flagZH from '../../img/zh-flag.png';

import logo from '../../img/logo-white.svg';

export function Navbar({ getTranslation }) {

  const [displayLanguageSelector, setDisplayLanguageSelector] = useState(false);
  const [cookies, setCookie] = useCookies(['cookies']);
  const [lang, setLang] = useState(cookies?.lang);


  function showLanguageSelector() {
    console.log("showLanguageSelector");
    setDisplayLanguageSelector(true);
  }

  function setLangEnglish() {
    setDisplayLanguageSelector(false);
    setCookie('lang', 'en', { path: '/' });
    setLang('en');
    window.location.reload(false);
  }

  function setLangChinese() {
    setDisplayLanguageSelector(false);
    setCookie('lang', 'zh', { path: '/' });
    setLang('zh');
    window.location.reload(false);
  }

  let currentFlag = flagEN;
  let currentLang = "ENGLISH";

  if (lang === "zh") {
    currentFlag = flagZH;
    currentLang = "中国人";
  }

  return (
    <>
      <div className={displayLanguageSelector ? "or-langSelDisplay display-block" : "or-langSelDisplay display-none"} id="langSelect">
        <div className="or-langSelOuter">
          <div className="or-LangSelInner">
            <div className="or-langSelLogo">
              <img className="or-langSelImg" src={logo} width="300px" alt="" />
            </div>
            <h1>SELECT A LANGUAGE:</h1>
            <button className="or-langSel-en" id="langEnglishBtn" onClick={setLangEnglish}>
              <img src={flagEN} id="select-english" alt="English" />ENGLISH
            </button>

            <button className="or-langSel-zh" id="langMandarinBtn" onClick={setLangChinese}>
              <img src={flagZH} id="select-mandarin" alt="Mandarin" />中国人
            </button>

          </div>

        </div>
      </div>

      <nav className="navbar header w-nav">
        <div className="container-default w-container">
          <div className="header-wrapper">
            <div className="split-content header-right">
              <Link to="/" className="header-logo-link w-nav-brand">
                <img src={logo} id="logoNavbar" alt="Open Racing Logo" className="header-logo" />
              </Link>
            </div>
            <div className="split-content header-middle">
              <div className="navbar-item">
                <Link to="/faq" id="menuFAQ">{getTranslation("menuFAQ")}</Link>
              </div>
              <div className="navbar-item">
                <button className="navbar-langselect" id="topLangBtn" onClick={showLanguageSelector}>
                  <img src={currentFlag} className="current-lang-flag" alt="Select a language" />
                  <span className="current-lang-text">{currentLang}</span>
                </button>
              </div>
              <Web3Button icon="show" label="Connect Wallet" balance="show" />
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

