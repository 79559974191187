import { Link } from 'react-router-dom';
import { useAccount, useNetwork, useContractRead } from 'wagmi';
import { useState, useEffect } from 'react';
import { Web3Button } from "@web3modal/react";
import BigNumber from 'bignumber.js';

import '../css/main.css';
import '../css/racing.css';

import { NETWORK, ADDRESSES, ABIS } from "../config";
import config from "../config.json";

import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { ChangeNetwork } from './components/ChangeNetwork';
import { RaceTile } from './components/RaceTile';

import backgroundVideo from '../videos/background-video.mp4';

function convertEntryFee(entryFee) {
  let convertedFee = BigNumber(entryFee.toString()).dividedBy(10 ** 18);
  return convertedFee.toString();
}
/*
function RaceGrid({ elementList }) {
  if (typeof (elementList) !== "undefined") {
    //console.log("RaceGrid Elements:" + elementList.length);

    const itemList = elementList.map((item) => (
      <div className="em-dr-raceinfo" key={"race-" + item.raceId.toString()}>
        <div className="em-dr-racenum"><h2>Race #{item.raceId.toString()}</h2></div>
        <div className="em-dr-lenght">Entry Fee: {convertEntryFee(item.entryFee)} AVAX</div>
        <div className="em-dr-lenght">Participants: {item.participantCount.toString()}/9</div>
        <div className="em-dr-plan"> <span className="em-dr-stage">Location:</span> {item.location} </div>
        <div className="em-dr-enterRace"> <Link to={"/race/" + item.raceId.toString()} className="dr-btn1">Race Details</Link> </div>
      </div>
    ));
    return (
      <>
        {itemList}
      </>
    );
  }
}
*/

function RaceGrid({ getTranslation, elementList }) {
  if (typeof (elementList) !== "undefined") {
    //console.log("RaceGrid Elements", elementList);
    //console.log("RaceGrid Elements:" + elementList.length);

    const itemList = elementList.map((item) => (
      <RaceTile raceId={item} getTranslation={getTranslation} key={"race-" + item} />
    ));
    return (
      <>
        {itemList}
      </>
    );
  }
}

export default function RaceList({ getTranslation, validChain }) {

  const { address } = useAccount();
  let isWalletConnected = (address !== null && typeof (address) !== 'undefined');

  const { chain } = useNetwork();
  let isNetworkCorrect = chain?.id === validChain?.id;

  const [activeRacesList, setActiveRacesList] = useState([]);
  const [concludedRacesList, setConcludedRacesList] = useState([]);
  const [currentPageId, setCurrentPageId] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [isRaceListLoading, setIsRaceListLoading] = useState(0);

  const useReadRaceCounter = useContractRead({
    address: ADDRESSES[NETWORK.id].race,
    abi: ABIS.race,
    functionName: "raceCounter",
    enabled: address !== undefined && chain !== undefined,
    watch: true,
    chainId: NETWORK.id,
  });

  const useReadActiveRaces = useContractRead({
    address: ADDRESSES[NETWORK.id].raceHelper,
    abi: ABIS.raceHelper,
    functionName: "getActiveRaces",
    enabled: address !== undefined,
    args: [30],
    watch: false,
    chainId: NETWORK.id,
  });

  const useReadFinishedRaces = useContractRead({
    address: ADDRESSES[NETWORK.id].raceHelper,
    abi: ABIS.raceHelper,
    functionName: "getFinishedRaces",
    enabled: config.PAGE_SIZE !== undefined,
    args: [config.PAGE_SIZE, currentPageId],
    watch: false,
    chainId: NETWORK.id,
    onSuccess(data) {
      console.log('useReadFinishedRaces args', config.PAGE_SIZE, currentPageId);
      console.log('useReadFinishedRaces data', data);
    },
  });

  window.useReadRaceCounter = useReadRaceCounter;
  window.useReadActiveRaces = useReadActiveRaces;
  window.useReadFinishedRaces = useReadFinishedRaces;

  window.test = useReadFinishedRaces;


  async function fetchRaces() {
    console.log('fetchRaces');
    if (useReadRaceCounter.data === undefined) return;
    if (useReadActiveRaces.data === undefined) return;
    if (useReadFinishedRaces.data === undefined) return;

    setIsRaceListLoading(true);

    //console.log(useReadActiveRaces.data)

    //================================
    //get active races

    let activeRaces = useReadActiveRaces.data;
    console.log("Active races", activeRaces);

    let loadedActiveRaces = [];
    let activeRaceCount = parseInt(activeRaces[1]); //activeRaces.activeRaceCount
    let activeRaceIds = activeRaces[0]; //activeRaces.raceIds

    for (let i = 0; i < activeRaceCount; i++) {
      loadedActiveRaces[i] = parseInt(activeRaceIds[i]);
    }


    //================================
    //get concluded races

    let pageSize = config.PAGE_SIZE;
    let raceCount = parseInt(useReadRaceCounter.data);
    let newMaxPage = Math.ceil(raceCount / pageSize) - 1;

    let loadedConcludedRaces = [];

    let finishedRaces = useReadFinishedRaces.data;

    let finishedRacesCount = parseInt(finishedRaces[6]); //finishedRaces.addedRaces
    let raceIds = finishedRaces[0]; //finishedRaces.raceIds


    for (let i = 0; i < finishedRacesCount; i++) {
      loadedConcludedRaces[i] = parseInt(raceIds[i]);
    }

    console.log("raceCounter", raceCount);
    console.log("activeRacesCount", activeRaceCount);
    console.log("loadedActiveRaces", loadedActiveRaces);
    console.log("finishedRaces", finishedRaces);
    console.log("loadedConcludedRaces", loadedConcludedRaces);


    if (newMaxPage !== maxPage) {
      setMaxPage(newMaxPage);
    }
    setActiveRacesList(loadedActiveRaces);
    setConcludedRacesList(loadedConcludedRaces);
    setIsRaceListLoading(false);

  }

  useEffect(() => {
    setIsRaceListLoading(true);
  }, [currentPageId]);

  function goToFirstPage() {
    setCurrentPageId(0);
  }

  function goToPrevPage() {
    let newPage = currentPageId - 1;
    if (newPage < 0) {
      newPage = 0;
    }
    setCurrentPageId(newPage);
  }

  function goToNextPage() {
    let newPage = currentPageId + 1;
    if (newPage > maxPage) {
      newPage = maxPage;
    }
    setCurrentPageId(newPage);
  }

  function goToLastPage() {
    setCurrentPageId(maxPage);
  }

  useEffect(() => {
    console.log("useEffect-fetchRaces", [
      useReadRaceCounter.data,
      useReadActiveRaces.data,
      useReadFinishedRaces.data]);
    if (!address) return;
    fetchRaces();
  }, [
    useReadRaceCounter.data,
    useReadActiveRaces.data,
    useReadFinishedRaces.data
  ]);

  return (
    <>
      <div className="page-wrapper">

        <Navbar getTranslation={getTranslation} />

        <div className="background-video-fixed">
          <video loop autoPlay muted playsInline data-object-fit="cover">
            <source src={backgroundVideo} data-wf-ignore="true" />
          </video>
        </div>

        <div className="main-wrapper">

          {
            isWalletConnected ? (
              isNetworkCorrect ? (

                <section className="em-race-ctrl">

                  <div className="blurred-bg-wrapper display-flex">

                    <div className="em-racesel-hdr">
                      <h1 className="text-raceSelection">Race Selection:</h1>
                    </div>
                    <div className="em-racesel-main" id="raceContainer">

                      <RaceGrid elementList={activeRacesList} getTranslation={getTranslation} />

                    </div>

                    <Link to="/createRace" className="dr-btn2 text-createRace" id="createRace">
                      Create Race
                    </Link>

                    <br /><br />

                    <div className="em-racesel-hdr text-pastRaces">
                      <h1>Past Races</h1>
                    </div>
                    <div className="em-racesel-main">
                      <div className="wrapper" id="concludedRacesContainer">
                        <RaceGrid elementList={concludedRacesList} getTranslation={getTranslation} />
                      </div>
                      <div className={isRaceListLoading ? "loader visible" : "loader hidden"} id="concludedRacesLoader"><h3>Loading...</h3></div>
                    </div>
                    <div className="em-page-nav" id="pageNav">
                      <button className="page-btn" onClick={goToFirstPage} disabled={isRaceListLoading || currentPageId <= 0}>&lt;&lt;</button>
                      <button className="page-btn" onClick={goToPrevPage} disabled={isRaceListLoading || currentPageId <= 0}>&lt;</button>
                      <h3 className="page-num">{currentPageId + 1}</h3>
                      <button className="page-btn" onClick={goToNextPage} disabled={isRaceListLoading || currentPageId >= maxPage}>&gt;</button>
                      <button className="page-btn" onClick={goToLastPage} disabled={isRaceListLoading || currentPageId >= maxPage}>&gt;&gt;</button>
                    </div>

                  </div>

                </section>
              ) : (
                <ChangeNetwork validChain={validChain} />
              )
            ) : (
              <section aria-label="Ethos Login Box" className="Ethos-login">
                <div className="em-loginbox">
                  <img className="em-loginlogo" src="" id="logoMain" alt="" />
                  <h1 className="text-login">Login</h1>
                  <p className="ethosHeroExp text-clickToConnect">Click below to connect</p>
                  <div className="em-btnsect">
                    <Web3Button icon="show" label="Connect Wallet" balance="show" />
                  </div>
                  <span className="em-warn1 text-loginNote">Note: Please make sure you're on the AVAX network!</span>
                </div>
              </section>
            )
          }

        </div>
        <Footer getTranslation={getTranslation} />
      </div>
    </>
  );
}