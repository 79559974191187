import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import DocumentMeta from 'react-document-meta';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum";
import { configureChains, createConfig, WagmiConfig, useAccount } from "wagmi";
//import { avalanche, avalancheFuji } from "wagmi/chains";
import { Web3Modal } from "@web3modal/react";
import { useCookies } from 'react-cookie';
import { NETWORK } from "./config";

//import config from "./config.json";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import Home from "./pages/Home";
import RaceList from "./pages/RaceList";
import Race from "./pages/Race";
import CreateRace from "./pages/CreateRace";
import FAQ from "./pages/FAQ";

import englishConfig from './languages/en.json';
import mandarinConfig from './languages/zh.json';
//import { useEffect } from "react";

let chains = [NETWORK];
/*
if (config.DEV_MODE === true) {
  chains = [avalancheFuji];
} else {
  chains = [avalanche];
}
*/

const web3ModalProjectId = "b1c042e866ec87c18ae1e356b0a8314c";

// Wagmi client
const { publicClient } = configureChains(chains, [
  w3mProvider({
    projectId: web3ModalProjectId
  }),
]);
const wagmiClient = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId: web3ModalProjectId, chains: chains }),
  publicClient
})

const ethereumClient = new EthereumClient(wagmiClient, chains);
//const web3modal = new Web3Modal({ web3ModalProjectId }, ethereumClient);

function App() {
  const { address } = useAccount();
  //let isWalletConnected = (address !== null && typeof (address) !== 'undefined');

  //const { setDefaultChain } = useWeb3Modal();
  //setDefaultChain(chains[0]);

  const [cookies, setCookie] = useCookies(['cookies']);
  let lang = cookies.lang;
  if(lang === undefined) {
    lang = "en";
  }

  function getTranslation(text) {
    let returnText = text;
    if (lang === "zh") {
      returnText = mandarinConfig["text"][text];
    } else if (lang === "en") {
      returnText = englishConfig["text"][text];
    }

    if (returnText === null || typeof (returnText) === "undefined" || returnText === "") {
      returnText = text;
    }

    //console.log("["+lang+"] "+text + " : " + returnText);

    return returnText;
  }

  const meta = {
    title: 'Open Racing',
    description: 'Open Racing - Grab yourself an eligible NFT and get ready to race!',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'crypto,web3,doge,moondoge,nft,racing,game'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <WagmiConfig config={wagmiClient}>
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route index element={<Home getTranslation={getTranslation} />} />
              <Route path="races" element={<RaceList getTranslation={getTranslation} validChain={chains[0]} />} />
              <Route path="createRace" element={<CreateRace getTranslation={getTranslation} validChain={chains[0]} />} />
              <Route path="race/:raceId" element={<Race getTranslation={getTranslation} validChain={chains[0]} />} />
              <Route path="faq" element={<FAQ getTranslation={getTranslation} validChain={chains[0]} />} />
              <Route path="*" element={<Navigate replace to="/" />} />

            </Routes>
          </BrowserRouter>
        </div>
        <ToastContainer />

        <Web3Modal
          projectId={web3ModalProjectId}
          ethereumClient={ethereumClient}
          themeMode={"dark"}
          themeColor={"blackWhite"}
          themeVariables={
            { "--w3m-accent-color": "#99952a" }
          }
        />
      </WagmiConfig>
    </DocumentMeta>
  );
}

export default App;
