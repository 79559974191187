import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAccount, useNetwork, useWaitForTransaction, useContractRead, useContractWrite, useContractEvent } from 'wagmi';
import { Web3Button } from "@web3modal/react";
import { formatEther } from 'viem';
import { toast } from 'react-toastify';
import { dogeBulkAPICall } from '../common/functions';

import { NETWORK, ADDRESSES, ABIS, ALLOWED_FEES_COUNT, COLLECTIONS } from "../config";

import '../css/main.css';
import '../css/racing.css';

import collectionDoge from '../img/collection-logos/doger-mod.png';
import collectionCyberlete from '../img/collection-logos/cyberlete.png';
import collectionDisc from '../img/collection-logos/disc-drone.png';
import collectionFrogz from '../img/collection-logos/smolfrogz.png';
import avaxLogo from '../img/avalanche-avax-logo.svg';

import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { ChangeNetwork } from './components/ChangeNetwork';
import { UserDogeList } from './components/UserDogeList';

import backgroundVideo from '../videos/background-video.mp4';

export default function CreateRace({ getTranslation, validChain }) {

  const [allowedEntryFees, setAllowedEntryFees] = useState(['500000000000000000','1000000000000000000','2500000000000000000','5000000000000000000','10000000000000000000','25000000000000000000']);
  const [allowedLocations, setAllowedLocations] = useState([{ id: -1, name: "loading" }]);

  const [nextCheckFeeId, setNextCheckFeeId] = useState(0);
  const [selectedFee, setSelectedFee] = useState('500000000000000000');
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [dogeSelectionActive, setDogeSelectionActive] = useState(false);
  const [newFoundRace, setNewFoundRace] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(null);


  const navigate = useNavigate();

  const { address } = useAccount();
  let isWalletConnected = (address !== null && typeof (address) !== 'undefined');

  const { chain } = useNetwork();
  let isNetworkCorrect = chain?.id === validChain?.id;

  const useReadAllowedFees = useContractRead({
    address: ADDRESSES[NETWORK.id].race,
    abi: ABIS.race,
    functionName: "allowedEntryFees",
    enabled: nextCheckFeeId < ALLOWED_FEES_COUNT,
    args: [nextCheckFeeId],
    watch: false,
  });

  const useReadAllowedLocations = useContractRead({
    address: ADDRESSES[NETWORK.id].raceHelper,
    abi: ABIS.raceHelper,
    functionName: "getAllSupportedLocations",
    watch: false,
  });

  const useWriteCreateRace = useContractWrite({
    address: ADDRESSES[NETWORK.id].race,
    abi: ABIS.race,
    functionName: "createRace",
    onError(error) {
      console.log('Error', error)
      toast.error("Creating race failed - try again");
    },
    onSuccess(data) {
      setDogeSelectionActive(false);
      console.log('createRace tx submitted', data);
      toast("Transaction pending...");
      const createTxResult = waitForCreateRace;
      console.log(createTxResult);
    },
  });

  const waitForCreateRace = useWaitForTransaction({
    hash: useWriteCreateRace.data?.hash,
    onSuccess(data) {
      toast.success("Race created successfully");
      console.log('Race created successfully', data);
    },
  });

  const useReadNewRace = useContractRead({
    address: ADDRESSES[NETWORK.id].race,
    abi: ABIS.race,
    functionName: "races",
    enabled: newFoundRace !== null,
    args: [newFoundRace],
    watch: false,
  });

  const unwatchRaceCreated = useContractEvent({
    address: ADDRESSES[NETWORK.id].race,
    abi: ABIS.race,
    eventName: 'RaceCreated',
    listener(log) {
      console.log(log);
      let newRaceId = parseInt(log[0].args.raceId);
      console.log("Found new race id", newRaceId);
      setNewFoundRace(newRaceId);
      //if (log.args.raceId === '0x...') unwatchRaceCreated()
    },
  });

  function checkNewFoundRace() {
    if (!waitForCreateRace.isSuccess) return;
    if (useReadNewRace.data === undefined) return;
    console.log('checkNewFoundRace');

    let initiator = useReadNewRace.data[0];
    console.log("Initiator of new race", initiator);

    if (initiator === address) {
      unwatchRaceCreated();
      toast.success("Found user created race - redirecting");
      navigate('/race/' + newFoundRace);
    }
  }

  async function fetchFees() {
    console.log('fetchFees');
    console.log('[TO DO] using a hardcoded set of fees for now');
    return;
    if (useReadAllowedFees.data === undefined) return;
    if (nextCheckFeeId >= ALLOWED_FEES_COUNT) return;

    let newAllowedEntryFees = allowedEntryFees;
    let newEntryFee = useReadAllowedFees.data;

    newAllowedEntryFees[nextCheckFeeId] = newEntryFee.toString();

    //console.log(nextCheckFeeId, newEntryFee);
    console.log(newAllowedEntryFees);

    setAllowedEntryFees(newAllowedEntryFees);

    if (nextCheckFeeId === 0) {
      setSelectedFee(newAllowedEntryFees[nextCheckFeeId]);
    }

    if (nextCheckFeeId < ALLOWED_FEES_COUNT) {
      setNextCheckFeeId(nextCheckFeeId + 1);
    }

  }
  async function fetchLocations() {
    console.log('fetchLocations');
    if (useReadAllowedLocations.data === undefined) return;

    let allLocations = useReadAllowedLocations.data;
    let enabledLocations = [];

    for (let i = 0; i < allLocations.length; i++) {
      if (allLocations[0][i])
        enabledLocations[enabledLocations.length] = { id: i, name: allLocations[1][i] };
    }

    setAllowedLocations(enabledLocations);

  }

  async function createRace(collectionAddress, tokenId) {
    //console.log('createRace');
    console.log("Creating a new race with doge #" + tokenId);
    let fee = selectedFee;

    toast("Confirm the transaction in your wallet");

    try {
      useWriteCreateRace.write({
        args: [collectionAddress, tokenId, selectedLocation, fee],
        value: fee,
      });
    } catch (e) {
      console.error(e);
    }

  }
  /*
    function fetchNewRace() {
      if(!waitForCreateRace.isSuccess) return;
      console.log('fetchNewRace');
  
    }*/

  function selectedFeeChange(newValue) {
    console.log(newValue)
    setSelectedFee(newValue);
  }

  function selectedLocationChange(newValue) {
    console.log(newValue)
    setSelectedLocation(newValue);
  }

  function exitDogeSelection() {
    setDogeSelectionActive(false);
  }

  useEffect(() => {
    fetchFees();
  }, [
    nextCheckFeeId,
  ]);

  useEffect(() => {
    fetchLocations();
  }, [useReadAllowedLocations.data]);

  /*
    //effect for finding new race after it's created
    useEffect(() => {
      fetchNewRace();
    }, [
      waitForCreateRace.isSuccess,
    ]);
    */

  useEffect(() => {
    checkNewFoundRace();
  }, [
    newFoundRace,
    useReadNewRace.data,
    waitForCreateRace.isSuccess
  ]);


  function selectDoge() {
    setSelectedCollection(COLLECTIONS.doge);
    setDogeSelectionActive(true);
  }

  function selectCyberlete() {
    setSelectedCollection(COLLECTIONS.cyberlete);
    setDogeSelectionActive(true);
  }

  function selectDisc() {
    setSelectedCollection(COLLECTIONS.disc);
    setDogeSelectionActive(true);
  }

  function selectFrog() {
    setSelectedCollection(COLLECTIONS.smolFrogz);
    setDogeSelectionActive(true);
  }

  
  return (
    <>
      <div className="page-wrapper">

        <Navbar activePage="home" getTranslation={getTranslation} />

        <div className="background-video">
          <video loop autoPlay muted playsInline data-object-fit="cover">
            <source src={backgroundVideo} data-wf-ignore="true" />
          </video>
        </div>

        <div className="main-wrapper">

          {
            isWalletConnected ? (
              isNetworkCorrect ? (
                <section className="em-race-ctrl">
                  {
                    dogeSelectionActive ? (
                      <div className="container">
                        <UserDogeList getTranslation={getTranslation} closeSelectionCallback={exitDogeSelection} selectDogeCallback={createRace} collectionAddress={selectedCollection} />
                      </div>
                    ) : (

                      <div className="blurred-bg-wrapper" id="screen-4">
                        <div className="em-dr-char-main">
                          <span className="dr-charselhdr">{getTranslation("selectEntryFee")}</span>

                          <div className="or-crSelectMain">
                            <div className="or-selectFee">
                              <span className="or-crSelDescTxt"> Choose Race Fee: </span>
                              <div className="or-selectFeeContainer">
                                <select name="entryFees" id="entryFees" className="or-select" value={selectedFee} onChange={(e) => selectedFeeChange(e.target.value)}>
                                  {allowedEntryFees.map((entryFee) => (
                                    <option value={entryFee} key={"option-" + entryFee}>{formatEther(entryFee)}</option>
                                  ))}
                                </select>
                                <img src={avaxLogo} alt="avax logo" /> AVAX</div>
                            </div>

                            <div className="or-selectFee">
                              <span className="or-crSelDescTxt"> Choose Race Location: </span>
                              <div className="or-selectFeeContainer">
                                <select name="locations" id="locations" className="or-select" value={selectedLocation} onChange={(e) => selectedLocationChange(e.target.value)}>
                                  {allowedLocations.map((location) => (
                                    <option value={location.id} key={"option-" + location.id} disabled={location.id<0}>{location.name}</option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="or-SelectNftMain">
                              <span className="or-crSelDescTxt"> NFT Character Selection: </span>
                              <div className="or-selNFTlist">
                                <button className="dr-btn3 select-nft-collection" id="selectCyberAthleteBtn" disabled={selectedFee === ''} onClick={selectCyberlete}>
                                  <img src={collectionCyberlete} alt="" />
                                  <br />
                                  Cyber Athlete
                                </button>
                                <button className="dr-btn3 select-nft-collection" id="selectDogeBtn" disabled={selectedFee === ''} onClick={selectDoge}>
                                  <img src={collectionDoge} alt="" />
                                  <br />
                                  Moon Doge
                                </button>
                                <button className="dr-btn3 select-nft-collection" id="selectDroneBtn" disabled={selectedFee === ''} onClick={selectDisc}>
                                  <img src={collectionDisc} alt="" />
                                  <br />
                                  DISC Drone
                                </button>
                                <button className="dr-btn3 select-nft-collection" disabled={selectedFee === ''} onClick={selectFrog}>
                                  <img src={collectionFrogz} alt="" />
                                  <br />
                                  Smol Frog
                                </button>

                              </div>
                            </div>
                            <br />
                            <Link to="/races" className="dr-btn2 text-back" id="button-screen4">Back</Link>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </section>
              ) : (
                <ChangeNetwork validChain={validChain} />
              )
            ) : (
              <section aria-label="Ethos Login Box" className="Ethos-login">
                <div className="em-loginbox">
                  <img className="em-loginlogo" src="" id="logoMain" alt="" />
                  <h1>{getTranslation("login")}</h1>
                  <p className="ethosHeroExp">{getTranslation("clickToConnect")}</p>
                  <div className="em-btnsect">
                    <Web3Button icon="show" label="Connect Wallet" balance="show" />
                  </div>
                  <span className="em-warn1">Note: Please make sure you're on the AVAX network!</span>
                </div>
              </section>
            )
          }
        </div>

        <Footer getTranslation={getTranslation} />
      </div>

    </>
  );
}