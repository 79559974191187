import { Link, useParams } from 'react-router-dom';
import { useAccount, useNetwork, useWaitForTransaction, useContractRead, useContractWrite } from 'wagmi';
//import { parseEther, formatEther } from 'viem';
import { useState, useEffect } from 'react';
import { Web3Button } from "@web3modal/react";
import { NETWORK, ADDRESSES, ABIS, COLLECTIONS } from "../config";
import BigNumber from 'bignumber.js';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import { dogeBulkAPICall } from '../common/functions';

import '../css/main.css';
import '../css/racing.css';

import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { ChangeNetwork } from './components/ChangeNetwork';
import { UserDogeList } from './components/UserDogeList';

import { doges2d, drones2d, athletes2d, frogz2d } from '../components/Doges2d';

import backgroundVideo from '../videos/background-video.mp4';
import playButton from '../img/play-button.svg';
import replayButton from '../img/replay-button.svg';


const dogeColors = ['#FDD975', '#DCD7D2', '#3A69E8', '#44CFA2', '#FDFC74', '#FFBD88', '#1FCECB', '#BC5D58', '#A8E4A0', '#FFAACC', '#1DF914', '#9D81BA', '#CD4A4A', '#FF9BAA', '#FBE8B5', '#FEDC6D', '#F663AF', '#FD6C85', '#8E4585', '#FF43A4', '#FFCFAB', '#FDBCB4', '#87A96B', '#FFA089', '#EFDBC5', '#5D76CB', '#C5D0E6', '#EA7E5D', '#FCB4D5', '#1DACD6', '#007FFF', '#E6A8D7', '#BAB86C', '#9FE2BF', '#1CA9C9', '#D68A59', '#660000', '#FC2847', '#E2E5E6', '#A5694F', '#80DAEB', '#FC89AC', '#DEAA88', '#77DDE7', '#926EAE', '#CDA4DE', '#FC74FD', '#8A795D', '#00040D', '#6DAE81'];
const dogeNames = ['shiba', 'doberman', 'husky', 'german-shepherd', 'golden-retriever', 'jack-russel-terrier', 'corgi'];


function convertEntryFee(entryFee) {
  let convertedFee = BigNumber(entryFee.toString()).dividedBy(10 ** 18);
  return convertedFee.toString();
}

function randomKeyNum() {
  let key = Math.floor(Math.random() * 10000000);
  return "rnd-key-" + key;
}


function decodeTraits(hexVal, collectionAddress) {
  let convertedArray = hexVal.toUpperCase().match(/[0-9A-F]{2}/g);
  let finalArray = [];

  console.log(collectionAddress, hexVal, convertedArray);
  console.log("COLLECTIONS.smolFrogz", COLLECTIONS.smolFrogz);

  finalArray[0] = dogeNames[parseInt(convertedArray[0], 16) - 1];
  finalArray[1] = dogeColors[parseInt(convertedArray[1], 16) - 1];

  let fillColor = finalArray[1];

  let silhouetteArray = [];
  let svg = '';
  if (collectionAddress.toLowerCase() === COLLECTIONS.cyberlete) {
    silhouetteArray = athletes2d;
    svg = silhouetteArray[0].split(' ');
  } else if (collectionAddress.toLowerCase() === COLLECTIONS.disc) {
    silhouetteArray = drones2d;
    svg = silhouetteArray[parseInt(convertedArray[0], 16) % silhouetteArray.length].split(' ');
  } else if (collectionAddress.toLowerCase() === COLLECTIONS.smolFrogz) {
    console.log("frog found");
    silhouetteArray = frogz2d;
    svg = silhouetteArray[0].split(' ');
    fillColor = "#779432";
  } else {
    silhouetteArray = doges2d;
    svg = silhouetteArray[parseInt(convertedArray[0], 16) - 1].split(' ');
  }
  let fillString = 'fill="' + fillColor + '" ';
  let newSvg = "";
  for (let i = 0; i < svg.length; i++) {
    newSvg = newSvg + svg[i] + " ";
    if (i === 0) {
      newSvg += fillString;
    }
  }

  finalArray[2] = parse(newSvg);

  return finalArray;
}

export default function Race({ getTranslation, validChain }) {

  const { address } = useAccount();
  let isWalletConnected = (address !== null && typeof (address) !== 'undefined');

  const { chain } = useNetwork();
  let isNetworkCorrect = chain.id === validChain.id;

  //const { data: signer } = useSigner();

  let { raceId } = useParams();

  const [currentRaceDetails, setCurrentRaceDetails] = useState({});
  const [processedRaceData, setProcessedRaceData] = useState({});
  const [isRaceLoaded, setIsRaceLoaded] = useState(false);
  const [dogeSelectionActive, setDogeSelectionActive] = useState(false);
  const [bulkNFTList, setBulkNFTList] = useState({ collections: [], ids: [] });
  const [selectedCollection, setSelectedCollection] = useState(null);


  const useReadRaceDetails = useContractRead({
    address: ADDRESSES[NETWORK.id].raceHelper,
    abi: ABIS.raceHelper,
    functionName: "getRaceDetails",
    enabled: raceId !== undefined,
    args: [raceId],
    watch: true,
    chainId: NETWORK.id,
  });

  const useReadRaceParticipants = useContractRead({
    address: ADDRESSES[NETWORK.id].raceHelper,
    abi: ABIS.raceHelper,
    functionName: "getRaceParticipants",
    enabled: raceId !== undefined,
    args: [raceId],
    watch: true,
  });

  const useReadRace = useContractRead({
    address: ADDRESSES[NETWORK.id].race,
    abi: ABIS.race,
    functionName: "races",
    enabled: raceId !== undefined,
    args: [raceId],
    watch: true,
    chainId: NETWORK.id,
  });

  const useReadDogesBulk = useContractRead({
    address: ADDRESSES[NETWORK.id].raceHelper,
    abi: ABIS.raceHelper,
    functionName: "getNFTsBulk",
    enabled: bulkNFTList.collections.length > 0,
    args: [bulkNFTList.collections, bulkNFTList.ids],
    watch: false,
    chainId: NETWORK.id,
  });


  //write functions

  const useWriteLeaveRace = useContractWrite({
    address: ADDRESSES[NETWORK.id].race,
    abi: ABIS.race,
    functionName: "leaveRace",
    onError(error) {
      console.log('Error', error)
      toast.error("Leaving race failed - try again");
    },
    onSuccess(data) {
      console.log('leaveRace tx submitted', data);
      toast("Transaction pending...");
      const leaveTxResult = waitForLeaveRace;
      console.log(leaveTxResult);
    },
  });

  const waitForLeaveRace = useWaitForTransaction({
    hash: useWriteLeaveRace.data?.hash,
    onSuccess(data) {
      toast.success("Race left successfully");
      console.log('Race left successfully', data);
      //setIsApproved(true);
    },
  });

  const useWriteEnterRace = useContractWrite({
    address: ADDRESSES[NETWORK.id].race,
    abi: ABIS.race,
    functionName: "enterRace",
    gas: '500000',
    onError(error) {
      console.log('Error', error)
      toast.error("Entering race failed - try again");
    },
    onSuccess(data) {
      setDogeSelectionActive(false);
      console.log('enterRace tx submitted', data);
      toast("Transaction pending...");
      const enterTxResult = waitForEnterRace;
      console.log(enterTxResult);
    },
  });

  const waitForEnterRace = useWaitForTransaction({
    hash: useWriteEnterRace.data?.hash,
    onSuccess(data) {
      toast.success("Entered race successfully");
      console.log('Entered race successfully', data);
    },
  });

  function racePlayback() {
    console.log("racePlayback");


  }

  function leaveRace(raceId, collectionAddress, nftId) {

    toast("Confirm the transaction in your wallet");

    try {
      useWriteLeaveRace.write({
        args: [raceId, collectionAddress, nftId]
      });
    } catch (e) {
      console.error(e);
    }

  }

  async function fetchRace() {
    console.log("fetchRace");

    if (useReadRaceDetails.data === undefined) return;
    if (useReadRaceParticipants.data === undefined) return;
    if (useReadRace.data === undefined) return;



    let newRaceDetails = useReadRaceDetails.data;
    let newRaceParticipants = useReadRaceParticipants.data;
    let newRaceDetails2 = useReadRace.data;
    let raceData = {};

    raceData.initiator = newRaceDetails[0];
    //raceData.entryFee = convertEntryFee(newRaceDetails[1]);
    raceData.entryFee = newRaceDetails[1];
    raceData.isFinished = newRaceDetails[2];
    raceData.isCancelled = newRaceDetails[3];
    raceData.participantCount = parseInt(newRaceDetails[4]);
    raceData.allParticipants = [];
    raceData.participants = [];
    raceData.finishedPositions = newRaceParticipants[2];
    raceData.isSlotTaken = newRaceParticipants[3];
    raceData.raceId = raceId;
    raceData.location = "Moon";

    let newParticipants = [];
    let newFinishedPositions = [];
    let allParticipants = [];

    console.log("newRaceParticipants", newRaceParticipants);

    for (let i = 0; i < 9; i++) {
      if (raceData.isSlotTaken[i]) {
        newParticipants[newParticipants.length] = { collection: newRaceParticipants[0][i], id: parseInt(newRaceParticipants[1][i]) };
      }
      //newFinishedPositions[i] = parseInt(raceData.finishedPositions[i]);
      if (raceData.isFinished) {
        console.log("isFinished");
        for (let j = 0; j < 9; j++) {
          if (parseInt(newRaceParticipants[2][j]) === i) {
            newFinishedPositions[i] = { collection: newRaceParticipants[0][j], id: parseInt(newRaceParticipants[1][j]) };
          }
        }
      }
      allParticipants[i] = { collection: newRaceParticipants[0][i], id: parseInt(newRaceParticipants[1][i]) };
    }
    raceData.finishedPositions = newFinishedPositions;
    raceData.allParticipants = allParticipants;

    raceData.participants = newParticipants;
    raceData.creationTime = newRaceDetails2[1];

    console.log("fetchRace", raceData);


    setCurrentRaceDetails(raceData);

    if (raceData.isFinished) {

      let newBulkNFTList = { collections: [], ids: [] };

      console.log("raceData", raceData);

      for (let i = 0; i < raceData.finishedPositions.length; i++) {
        newBulkNFTList.collections[i] = raceData.finishedPositions[i].collection;
        newBulkNFTList.ids[i] = raceData.finishedPositions[i].id;
      }

      setBulkNFTList(newBulkNFTList);
    } else {

      let newBulkNFTList = { collections: [], ids: [] };

      for (let i = 0; i < raceData.participants.length; i++) {
        newBulkNFTList.collections[i] = raceData.participants[i].collection;
        newBulkNFTList.ids[i] = raceData.participants[i].id;
      }

      setBulkNFTList(newBulkNFTList);
    }
  }

  async function fetchDogesAndUpdateData() {
    console.log("fetchDogesAndUpdateData");

    if (currentRaceDetails.isCancelled) {
      let newRaceData = {
        isCancelled: true,
      };

      setProcessedRaceData(newRaceData);

      return;
    }

    console.log("test1");
    window.test = useReadDogesBulk;
    window.test2 = bulkNFTList;
    if (useReadDogesBulk.data === undefined) return;
    console.log("test2");

    let raceData = currentRaceDetails;

    console.log("raceData", raceData);

    let colorClass = 'color-yellow';
    let text = getTranslation("waitingForParticipants");

    if (raceData.isFinished) {
      colorClass = 'color-green';
      text = getTranslation("finished");
    } else if (raceData.isCancelled) {
      colorClass = 'color-red';
      text = getTranslation("cancelled");
    } else if (raceData.participantCount === 9) {
      colorClass = 'color-green';
      text = getTranslation("inProgress");
    }

    let displayRaceVid = false;
    let minTimeInMs = 5000;

    let canJoinRace = !raceData.isFinished && !raceData.isCancelled && raceData.participantCount < 9;


    if (raceData.isFinished) {
      console.log("raceData.finishedPositions", raceData.finishedPositions);

      await dogeBulkAPICall(raceData.finishedPositions).then((APIData) => {
        console.log("APIData", APIData);

        let APIArrayById = [];
        for (let i = 0; i < 9; i++) {
          if (APIArrayById[APIData[i].collectionAddress] === undefined) {
            APIArrayById[APIData[i].collectionAddress] = [];
          }
          APIArrayById[APIData[i].collectionAddress][APIData[i].id] = APIData[i];
        }

        let APIDataSorted = [];
        for (let i = 0; i < 9; i++) {
          for (let j = 0; j < 9; j++) {
            if (APIData[j].collectionAddress.toLowerCase() === raceData.finishedPositions[i].collection.toLowerCase() && APIData[j].id === raceData.finishedPositions[i].id) {
              //console.log(i, APIData[j]);
              APIDataSorted[i] = APIData[j];
              APIArrayById[APIData[j].collectionAddress][APIData[j].id].encodedTraits = APIDataSorted[i].encodedTraits;
            }
          }
        }
        console.log("APIData", APIData);
        console.log("APIDataSorted", APIDataSorted);
        console.log("raceData.finishedPositions", raceData.finishedPositions);
        console.log("raceData", raceData);

        let dogeBulkData = useReadDogesBulk.data;


        let dogeOwners = dogeBulkData[0]//dogeBulkData.owners
        let dogeOwnerClass = [];

        for (let i = 0; i < 9; i++) {

          let nftCollectionId = raceData.finishedPositions[i].collection.toLowerCase();
          let nftId = parseInt(raceData.finishedPositions[i].id);

          let isUsersDogeClass = ``;

          if (dogeOwners[i] === address) {
            isUsersDogeClass = `user-doge`;
          }
          if (dogeOwnerClass[nftCollectionId] === undefined) {
            dogeOwnerClass[nftCollectionId] = [];
          }
          dogeOwnerClass[nftCollectionId][nftId] = isUsersDogeClass;
        }

        console.log("dogeOwnerClass", dogeOwnerClass);


        let racingDoges = [];
        let finishedPositions = [];
        let participantDoges = [];

        for (let i = 0; i < 9; i++) {
          let thisDoge = APIDataSorted[i].id;
          let decodedTraits = decodeTraits(APIArrayById[APIDataSorted[i].collectionAddress][thisDoge].encodedTraits, APIArrayById[APIDataSorted[i].collectionAddress][thisDoge].collectionAddress);


          let doge = {};
          doge.id = i;
          doge.collectionAddress = APIDataSorted[i].collectionAddress;
          doge.nftId = APIDataSorted[i].id;
          doge.name = APIDataSorted[i].name;
          doge.speed = APIDataSorted[i].speed;
          doge.endurance = APIDataSorted[i].endurance;
          doge.luck = APIDataSorted[i].luck;
          doge.encodedTraits = APIArrayById[doge.collectionAddress][thisDoge].encodedTraits;
          doge.image = APIDataSorted[i].image;
          doge.dogeDisplayId = APIArrayById[doge.collectionAddress][thisDoge].id;
          doge.decodedTraits = decodedTraits[2];
          doge.isOwnerClass = dogeOwnerClass[doge.collectionAddress][thisDoge];
          doge.svg = decodedTraits[2];
          doge.emptySlot = false;
          racingDoges[racingDoges.length] = doge;
          finishedPositions[i] = raceData.finishedPositions[i];

          for (let j = 0; j < 9; j++) {
            if (doge.collectionAddress.toLowerCase() === raceData.allParticipants[j].collection.toLowerCase() && parseInt(doge.nftId) === parseInt(raceData.allParticipants[j].id)) {
              participantDoges[i] = doge;
            }
          }
        }

        let newRaceData = {
          participantCount: raceData.participantCount,
          statusColor: colorClass,
          statusText: text,
          racingDoges: racingDoges,
          participants: raceData.allParticipants,
          finishedPositions: finishedPositions,
          participantDoges: participantDoges,
          canJoinRace: canJoinRace,
          isFinished: true,
          isCancelled: raceData.isCancelled,
        };

        console.log("newRaceData", newRaceData);
        setProcessedRaceData(newRaceData);
        setIsRaceLoaded(true);

      });

    } else {

      if (raceData.participants === undefined) {
        return;
      }

      await dogeBulkAPICall(raceData.participants).then((APIData) => {
        console.log("APIData", APIData);
        console.log("raceData.participants", raceData.participants);

        let APIDataSorted = [{}, {}, {}, {}, {}, {}, {}, {}, {}];
        for (let i = 0; i < raceData.allParticipants.length; i++) {
          for (let j = 0; j < raceData.participants.length; j++) {
            if (APIData[j].collectionAddress.toLowerCase() === raceData.allParticipants[i].collection.toLowerCase() && APIData[j].id === raceData.allParticipants[i].id) {
              console.log("found a match", i, j, APIData[j].collectionAddress, APIData[j].id);
              APIDataSorted[i] = APIData[j];
              break;
            }
          }
        }

        console.log("APIDataSorted", APIDataSorted);

        let dogeBulkData = useReadDogesBulk.data;
        
        let dogeOwners = dogeBulkData[0]//dogeBulkData.owners
        let dogeOwnerClass = [];
        let dogeOwnerArray = [];

        for (let i = 0; i < raceData.participants.length; i++) {
          let nftId = parseInt(raceData.participants[i].id);
          let nftCollectionId = raceData.participants[i].collection.toLowerCase();
          
          let isUsersDogeClass = ``;
          let isUsersDoge = false;

          if (dogeOwners[i] === address) {
            isUsersDogeClass = `user-doge`;
            isUsersDoge = true;
          }
          if (dogeOwnerClass[nftCollectionId] === undefined) {
            dogeOwnerClass[nftCollectionId] = [];
            dogeOwnerArray[nftCollectionId] = [];
          }
          dogeOwnerClass[nftCollectionId][nftId] = isUsersDogeClass;
          dogeOwnerArray[nftCollectionId][nftId] = isUsersDoge;
        }


        let racingDoges = [];

        for (let i = 0; i < 9; i++) {
          let isLeaveButtonEnabled = false;
          if (parseInt(raceData.creationTime) + 3600 < Math.floor(new Date / 1000)) {
            isLeaveButtonEnabled = true;
          }

          let thisDoge = APIDataSorted[i].id;

          let doge = {};
          doge.id = i;
          doge.nftId = thisDoge;
          doge.name = APIDataSorted[i].name;
          doge.collectionAddress = APIDataSorted[i].collectionAddress;
          doge.speed = APIDataSorted[i].speed;
          doge.endurance = APIDataSorted[i].endurance;
          doge.luck = APIDataSorted[i].luck;
          doge.image = APIDataSorted[i].image;
          doge.dogeDisplayId = APIDataSorted[i].id;
          if(thisDoge !== undefined) {
            doge.isOwnerClass = dogeOwnerClass[doge.collectionAddress][thisDoge];
            doge.isUsersDoge = dogeOwnerArray[doge.collectionAddress][thisDoge];
          } else {
            doge.isOwnerClass = ``;
            doge.isUsersDoge = false;
          }
          doge.emptySlot = !raceData.isSlotTaken[i];
          doge.isLeaveButtonEnabled = isLeaveButtonEnabled;
          racingDoges[racingDoges.length] = doge;
        }

        let newRaceData = {
          participantCount: raceData.participantCount,
          statusColor: colorClass,
          statusText: text,
          racingDoges: racingDoges,
          canJoinRace: canJoinRace,
          isFinished: false,
          isCancelled: raceData.isCancelled,
        };

        console.log("newRaceData", newRaceData);//todo
        setProcessedRaceData(newRaceData);
        setIsRaceLoaded(true);

      });
    }
  }


  useEffect(() => {
    fetchDogesAndUpdateData();
  }, [useReadDogesBulk.data, currentRaceDetails])

  async function selectDoge() {
    setDogeSelectionActive(true);
  }

  function exitDogeSelection() {
    setDogeSelectionActive(false);
  }

  async function joinRace(collectionAddress, tokenId) {
    console.log("Joining race " + raceId + " with doge #" + tokenId);
    console.log(currentRaceDetails.entryFee.toString());
    let fee = currentRaceDetails.entryFee.toString();

    toast("Confirm the transaction in your wallet");

    try {
      useWriteEnterRace.write({
        args: [raceId, collectionAddress, tokenId],
        value: fee,
      });
    } catch (e) {
      console.error(e);
    }
  }

  const [raceWrapperClass, setRaceWrapperClass] = useState('inactive');
  const [playbackDogesOffsets, setPlaybackDogesOffsets] = useState(['2%', '2%', '2%', '2%', '2%', '2%', '2%', '2%', '2%']);
  const [playbackDoges, setPlaybackDoges] = useState([]);

  async function replayRace() {
    console.log("replayRace");

    const intervalLoops = 20;
    const intervalTime = 500;

    //reset positions
    //start interval etc
    setPlaybackDogesOffsets(['2%', '2%', '2%', '2%', '2%', '2%', '2%', '2%', '2%']);

    setTimeout(function () {
      setRaceWrapperClass("replaying");

      const topPositionOffsets = ['calc(100% - 60px)', 'calc(99.5% - 60px)', 'calc(99% - 60px)', 'calc(98.5% - 60px)', 'calc(98% - 60px)', 'calc(97.5% - 60px)', 'calc(97% - 60px)', 'calc(96.5% - 60px)', 'calc(96.5% - 60px)'];
      let intervalCounter = 0;
      let previousOffsets = [2, 2, 2, 2, 2, 2, 2, 2, 2];

      animateRace();
      let raceInterval = setInterval(animateRace, intervalTime);

      function animateRace() {
        let newOffsets = [2, 2, 2, 2, 2, 2, 2, 2, 2];
        if (intervalCounter < intervalLoops) {
          let racingDoges = processedRaceData.participants;
          let finishingPositions = processedRaceData.finishedPositions;

          for (let i = 0; i < 9; i++) {

            if (intervalCounter === intervalLoops - 1) {
              for (let j = 0; j < finishingPositions.length; j++) {
                if (parseInt(racingDoges[i].id) === parseInt(finishingPositions[j].id) && racingDoges[i].collection.toLowerCase() === finishingPositions[j].collection.toLowerCase()) {
                  newOffsets[i] = topPositionOffsets[j];
                }
              }
            } else {
              let rngRange = Math.floor(100 / intervalLoops);
              let rngMin = intervalCounter * rngRange;
              let rngMax = intervalCounter * rngRange + rngRange;

              let finalOffset = (rngMin + getRandomInt(rngMax - rngMin));
              let previousOffset = typeof (previousOffsets[i]) == 'undefined' ? 2 : previousOffsets[i];

              if (finalOffset <= previousOffset) {
                finalOffset = previousOffset;
              }
              previousOffsets[i] = finalOffset;
              let leftOffset = finalOffset + '%';
              newOffsets[i] = leftOffset;
            }
          }
          intervalCounter++;
          setPlaybackDogesOffsets(newOffsets);
        } else {
          clearInterval(raceInterval);
          raceInterval = null;
          setRaceWrapperClass("");
          setTimeout(function () {
            setRaceWrapperClass("finished");
          }, intervalTime);
        }
        return;
      }

      function getRandomInt(max) {
        return Math.floor(Math.random() * max);
      }
    }, 100);

  }

  useEffect(() => {
    //if (!address) return;
    fetchRace();

  }, [
    address,
    useReadRaceDetails.data,
    useReadRace.data,
    waitForLeaveRace.isSuccess,
    waitForEnterRace.isSuccess
  ]);

  return (
    <>
      <div className="page-wrapper">

        <Navbar getTranslation={getTranslation} />

        <div className="background-video-fixed">
          <video loop autoPlay muted playsInline data-object-fit="cover">
            <source src={backgroundVideo} data-wf-ignore="true" />
          </video>
        </div>

        <div className="main-wrapper">

          {
            isWalletConnected ? (
              isNetworkCorrect ? (

                <section className="em-race-ctrl">
                  <div className="blurred-bg-wrapper display-flex">
                    <div className="em-dr-racedetails-main">
                      <div className="container">
                        {dogeSelectionActive ? (
                          <UserDogeList getTranslation={getTranslation} collectionAddress={selectedCollection} closeSelectionCallback={exitDogeSelection} selectDogeCallback={joinRace} dogeBulkAPICall={dogeBulkAPICall} />
                        ) : (
                          <>
                            <span className="dr-racedetailshdr" id="raceHeader">Race #{raceId} details</span>
                            <br /><br />
                            <div id="raceContent">
                              {
                                (isRaceLoaded && !!processedRaceData.participantCount) ? (
                                  <>
                                    <div>{getTranslation("participants")}: {processedRaceData.participantCount}/9</div>
                                    <div>{getTranslation("location")}: Moon</div>
                                    <div>{getTranslation("status")}:
                                      <span className={processedRaceData.statusColor}> {processedRaceData.statusText}</span>
                                      {
                                        processedRaceData.canJoinRace ? (
                                          <div className="em-dr-enterRaceBtn">
                                            <button onClick={selectDoge} className="dr-btn1">{getTranslation("enterRace")}</button>
                                          </div>
                                        ) : null
                                      }
                                    </div>
                                    {
                                      processedRaceData.isFinished ? (
                                        <>
                                          <div className={"race-wrapper " + raceWrapperClass}>
                                            <div className="race-cover">
                                              <div className="replay-wrapper">
                                                <span>Replay Race</span>
                                                <button onClick={replayRace}><img src={playButton} alt="Play race" /></button>
                                              </div>
                                            </div>
                                            <div className="race-cover-finished">
                                              <div className="replay-wrapper">
                                                <span>{getTranslation("finished")}</span>
                                                <button onClick={replayRace}><img src={replayButton} alt="Replay race" /></button>
                                              </div>
                                            </div>
                                            <div className="race-visualised">
                                              <div className="vertical-lines">
                                                <div className="line" style={{ "left": "20%" }}><span>100m</span><div className="filling"></div></div>
                                                <div className="line" style={{ "left": "40%" }}><span>200m</span><div className="filling"></div></div>
                                                <div className="line" style={{ "left": "60%" }}><span>300m</span><div className="filling"></div></div>
                                                <div className="line" style={{ "left": "80%" }}><span>400m</span><div className="filling"></div></div>
                                              </div>

                                              {processedRaceData.participantDoges.map((doge, i) => (
                                                <div className="racingDogeCol" data-nftid={doge.nftId} key={"sim-" + doge.collectionAddress + "-" + doge.nftId}>
                                                  <div className="racingDoge" style={{ "left": playbackDogesOffsets[i] }}>
                                                    <span>#{doge.nftId}</span>
                                                    {doge.svg}
                                                  </div>
                                                </div>
                                              ))}

                                            </div>
                                          </div>


                                          <br /><br />
                                          <h2> {getTranslation("finishingPositions")}</h2 >
                                          <div className="row positionBoxesContainer">
                                            {processedRaceData.racingDoges.map((doge) => (
                                              <div className="col doge-card-column" key={"finished-" + doge.collectionAddress + "-" + doge.nftId}>
                                                <div className={"doge-card " + doge.isOwnerClass}>
                                                  <div className="finishing-position">#{doge.id + 1}</div>
                                                  {doge.name}<br />
                                                  <img src={doge.image} alt="" /><br />
                                                  <div className="trait"><span>{getTranslation("speed")}:</span> <span>{doge.speed}</span></div>
                                                  <div className="trait"><span>{getTranslation("endurance")}:</span> <span>{doge.endurance}</span></div>
                                                  <div className="trait"><span>{getTranslation("luck")}:</span> <span>{doge.luck}</span></div><br />
                                                </div>
                                              </div>
                                            ))}
                                          </div>

                                        </>
                                      ) : (
                                        <>
                                          <br /><br />
                                          <h2> {getTranslation("participants")}</h2 >
                                          <div className="row">
                                            {processedRaceData.racingDoges.map((doge) => (
                                              doge.emptySlot ? (
                                                <div className="col" key={randomKeyNum()}>
                                                  <div className="doge-card">
                                                    {getTranslation("emptySlot")}<br />
                                                    <img src="" alt="" /><br />
                                                    <div className="trait"><span>{getTranslation("speed")}:</span> <span>0</span></div>
                                                    <div className="trait"><span>{getTranslation("endurance")}:</span> <span>0</span></div>
                                                    <div className="trait"><span>{getTranslation("luck")}:</span> <span>0</span></div><br />
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className="col" key={"participant-" + doge.collectionAddress + "-" + doge.nftId}>
                                                  <div className={"doge-card " + doge.isOwnerClass}>
                                                    {doge.name}<br />
                                                    <img src={doge.image} alt="" /><br />
                                                    <div className="trait"><span>{getTranslation("speed")}:</span> <span>{doge.speed}</span></div>
                                                    <div className="trait"><span>{getTranslation("endurance")}:</span> <span>{doge.endurance}</span></div>
                                                    <div className="trait"><span>{getTranslation("luck")}:</span> <span>{doge.luck}</span></div><br />
                                                    {
                                                      doge.isUsersDoge ? (
                                                        <div className="em-dr-leaveRace">
                                                          <button onClick={() => { leaveRace(raceId, doge.collectionAddress, doge.nftId) }} className="dr-btn1" disabled={!doge.isLeaveButtonEnabled}>{getTranslation("leaveRace")}</button>
                                                        </div>
                                                      ) : null
                                                    }
                                                  </div>
                                                </div>
                                              )
                                            ))}
                                          </div>
                                        </>
                                      )
                                    }
                                  </>

                                ) : (
                                  <>
                                    {(processedRaceData.isCancelled) ? (
                                      <span>This race was cancelled.</span>
                                    ) : (
                                      <span>{getTranslation("loading")}</span>
                                    )}
                                  </>
                                )
                              }
                            </div>
                            <br /><br />
                            <Link to="/races" className="dr-btn2" id="button-screen5">{getTranslation("back")}</Link>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              ) : (
                <ChangeNetwork validChain={validChain} />
              )
            ) : (
              <section aria-label="Ethos Login Box" className="Ethos-login">
                <div className="em-loginbox">
                  <img className="em-loginlogo" src="" id="logoMain" alt="" />
                  <h1>{getTranslation("login")}</h1>
                  <p className="ethosHeroExp">{getTranslation("clickToConnect")}</p>
                  <div className="em-btnsect">
                    <Web3Button icon="show" label="Connect Wallet" balance="show" />
                  </div>
                  <span className="em-warn1">{getTranslation("loginNote")}</span>
                </div>
              </section>
            )
          }

        </div>
        <Footer getTranslation={getTranslation} />
      </div>
    </>
  );
}