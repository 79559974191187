import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAccount, useContractRead } from 'wagmi';
import BigNumber from 'bignumber.js';
import { NETWORK, ADDRESSES, ABIS } from "../../config";

//todo: add outline for user-joined tiles

function convertEntryFee(entryFee) {
  let convertedFee = BigNumber(entryFee.toString()).dividedBy(10 ** 18);
  return convertedFee.toString();
}

export function RaceTile({ getTranslation, raceId }) {

  const [raceDetails, setRaceDetails] = useState({ isSet: false, raceID: '-', entryFee: '-', participantCount: '-', location: '-' });

  const { address } = useAccount();

  const useReadRaceDetails = useContractRead({
    address: ADDRESSES[NETWORK.id].raceHelper,
    abi: ABIS.raceHelper,
    functionName: "getRaceDetails",
    enabled: raceId !== undefined,
    args: [raceId],
    watch: false,
  });

  const useReadRaceParticipants = useContractRead({
    address: ADDRESSES[NETWORK.id].raceHelper,
    abi: ABIS.raceHelper,
    functionName: "getRaceParticipants",
    enabled: raceId !== undefined,
    args: [raceId],
    watch: false,
  });

  const useReadUserDoges = useContractRead({
    address: ADDRESSES[NETWORK.id].raceHelper,
    abi: ABIS.raceHelper,
    functionName: "getUserCharacters",
    enabled: address !== undefined,
    args: ["0xc31191730f21b9c336904ab93d6bbc582df54ebb", address],//TODO 0x3e2B2D600EdC8287c4a564E4370Ed4228BFE0BA2
    watch: false,
  });

  async function fetchRace() {
    //console.log('fetchRace');
    if (useReadRaceDetails.data === undefined) return;
    if (useReadRaceParticipants.data === undefined) return;
    if (useReadUserDoges.data === undefined) return;
    if (convertEntryFee(useReadRaceDetails.data[1]) === "0") return;
    
    let newRaceDetails = useReadRaceDetails.data;
    let newRaceParticipants = useReadRaceParticipants.data;
    let userDoges = useReadUserDoges.data;
    
    let newRaceData = {};

    newRaceData.initiator = newRaceDetails[0];
    newRaceData.entryFee = convertEntryFee(newRaceDetails[1]);
    newRaceData.isFinished = newRaceDetails[2];
    newRaceData.isCancelled = newRaceDetails[3];
    newRaceData.participantCount = parseInt(newRaceDetails[4]);
    /*
    newRaceData.participants = newRaceDetails[5];
    newRaceData.finishedPositions = newRaceDetails[6];
    newRaceData.isSlotTaken = newRaceDetails[7];
    */
    newRaceData.raceId = raceId;
    newRaceData.location = "Moon";
    newRaceData.isSet = true;
    newRaceData.userJoined = false;

    let dogeIds = userDoges[0];
/*
    for(let i=0; i<newRaceData.participantCount; i++) {
      if(dogeIds.includes(newRaceData.participants[i])) {
        newRaceData.userJoined = true;
      }
    }
    */
    //console.log(newRaceData);
    console.log("newRaceData",newRaceData);

    setRaceDetails(newRaceData);
  }

  useEffect(() => {
    fetchRace();
  }, [
    raceId,
    useReadRaceDetails.data,
    useReadUserDoges.data
  ]);

  return (
    <>
      <div className={raceDetails.userJoined ? "em-dr-raceinfo user-joined" : "em-dr-raceinfo"}>
        <div className="em-dr-racenum"><h2>{getTranslation("race")} #{raceDetails.raceId}</h2></div>
        <div className="em-dr-lenght">{getTranslation("entryFee")}: {raceDetails.entryFee} AVAX</div>
        <div className="em-dr-lenght">{getTranslation("participants")}: {raceDetails.participantCount}/9</div>
        <div className="em-dr-plan"> <span className="em-dr-stage">{getTranslation("location")}:</span> {raceDetails.location} </div>
        <div className="em-dr-enterRace"> <Link to={"/race/" + raceDetails.raceId} className="dr-btn1">{getTranslation("raceDetails")}</Link> </div>
      </div>
    </>
  );
}