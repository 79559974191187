import { Link } from 'react-router-dom';

import '../css/main.css';
import '../css/racing.css';

import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';

import backgroundVideo from '../videos/background-video.mp4';

export default function Home({ getTranslation }) {
  return (
    <>
      <div className="page-wrapper">

        <Navbar activePage="home" getTranslation={getTranslation} />

        <div className="background-video">
          <video loop autoPlay muted playsInline data-object-fit="cover">
            <source src={backgroundVideo} data-wf-ignore="true" />
          </video>

        </div>
        <div className="main-wrapper">

          <div className="section home-hero wf-section">
            <div className="container-default">
              <div>
                <div className="grid-2-columns home-hero">
                  <div className="div-block">
                    <h1 id="indexTitle">
                      Welcome to Open Racing!
                    </h1>
                    <div className="inner-container-550px">
                      <p id="indexText">
                        Step 1) Click the "Mint Ethos NFT" button to mint an NFT if you do not have one.
                        <br />
                        Step 2) Click the "Enter Race" button to connect your wallet and enter a race.<br />
                        Eligible NFTs include:<br />
                        Moon Doges<br />
                        Cyberlete Cyberathlete<br />
                        DISC drone
                      </p>
                    </div>
                    <div className="_2-button-wrap mg-top-48px">
                      <Link to="https://mint.biofitoken.io/" target="_blank" className="button-primary _2-buttons w-button" id="mintDogeBtn">Mint Ethos NFT</Link>
                      <Link to="/races" className="button-primary _2-buttons w-button" id="enterRaceBtn">Enter Race</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer getTranslation={getTranslation} />
      </div>

    </>
  );
}