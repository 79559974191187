import * as React from 'react';
import { useState, useEffect } from 'react';
import { useAccount, useContractRead } from 'wagmi';
import { NETWORK, ADDRESSES, ABIS, COLLECTIONS } from "../../config";
import { dogeBulkAPICall } from '../../common/functions';

import { Client, cacheExchange, fetchExchange } from '@urql/core';

import collectionDoge from '../../img/collection-logos/doger-mod.png';
import collectionCyberlete from '../../img/collection-logos/cyberlete.png';
import collectionDisc from '../../img/collection-logos/disc-drone.png';
import collectionFrogz from '../../img/collection-logos/smolfrogz.png';


const APIURL = 'https://api.thegraph.com/subgraphs/name/arrogantx/smolfrogz'; //mainnet
//const APIURL = 'https://api.thegraph.com/subgraphs/name/arrogantx/testnet_frogz'; //testnet

const client = new Client({
  url: APIURL,
  exchanges: [cacheExchange, fetchExchange],
})

export function UserDogeList({ getTranslation, collectionAddress, closeSelectionCallback, selectDogeCallback }) {

  const { address } = useAccount();

  const [userDoges, setUserDoges] = useState(null);
  const [userFrogz, setUserFrogz] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(collectionAddress);
  

  const useReadBalance = useContractRead({
    address: ADDRESSES[NETWORK.id].doge,
    abi: ABIS.doge,
    functionName: "balanceOf",
    enabled: address !== undefined,
    args: [address],
    watch: false,
  });

  const useReadUserCharacters = useContractRead({
    address: ADDRESSES[NETWORK.id].raceHelper,
    abi: ABIS.raceHelper,
    functionName: "getUserCharacters",
    enabled: address !== undefined && selectedCollection !== undefined && selectedCollection !== null && selectedCollection !== COLLECTIONS.smolFrogz,
    args: [selectedCollection, address],
    watch: false,
  });

  const useReadUserFrogz = useContractRead({
    address: ADDRESSES[NETWORK.id].raceHelper,
    abi: ABIS.raceHelper,
    functionName: "getNFTsBulk",
    enabled: address !== undefined && userFrogz !== undefined && userFrogz !== null && selectedCollection === COLLECTIONS.smolFrogz,
    args: [userFrogz?.collections, userFrogz?.ids],
    watch: false,
  });

  useEffect(() => {
    getUserCharacters();

  }, [useReadBalance.data, useReadUserCharacters.data]);

  useEffect(() => {
    getUserFrogz();

  }, [useReadBalance.data, useReadUserFrogz.data]);

  async function fetchSmolFrogz() {

    if(address === undefined) {
      return;
    }

    const tokensQuery = `
    query {
        user(id: "${address.toLowerCase()}") { 
        id
            tokens {
            id
            }
        }
    }
    `
    const data = await client.query(tokensQuery).toPromise()
    let resArray = []
    if (data.data && data.data.user && data.data.user.tokens) {
      for (let i = 0; i < data.data.user.tokens.length; i++) {
        resArray.push(data.data.user.tokens[i].id)
      }
    }
    console.log("Fetched result", resArray);

    
    let newUserFrogz = {collections: [], ids: []};

    console.log("resArray",resArray);

    for(let i=0; i<resArray.length; i++) {
      newUserFrogz.collections[i] = COLLECTIONS.smolFrogz;
      newUserFrogz.ids[i] = resArray[i];
    }
    console.log("newUserFrogz",newUserFrogz);

    setUserFrogz(newUserFrogz);
  }

  useEffect(() => {
    if (selectedCollection === COLLECTIONS.smolFrogz) {
      console.log("Smol Frogz selected. Fetching API...");
      fetchSmolFrogz();
    }

  }, [selectedCollection, address]);


  async function getUserCharacters() {
    console.log("getUserCharacters");

    if (useReadBalance.data === undefined) return;
    if (useReadUserCharacters.data === undefined) return;


    let userOwnedCharacterCount = parseInt(useReadBalance.data);
    console.log("userOwnedCharacterCount", userOwnedCharacterCount);

    let tempUserDoges = [];
    let dogesBusy = [];
    let isDogeBusy = [];

    let userDogesResponse = useReadUserCharacters.data;

    let dogeIds = userDogesResponse[0];
    dogesBusy = userDogesResponse[1];

    for (let i = 0; i < dogeIds.length; i++) {
      tempUserDoges[i] = { collection: selectedCollection, id: parseInt(dogeIds[i]) };
      isDogeBusy[tempUserDoges[i].id] = dogesBusy[i];
    }

    if (tempUserDoges.length > 0) {
      dogeBulkAPICall(tempUserDoges).then((APIData) => {
        let userDogesToSave = [];

        for (let i = 0; i < APIData.length; i++) {
          let newDoge = {
            name: APIData[i].name,
            dogeId: APIData[i].id,
            image: APIData[i].image,
            speed: APIData[i].speed,
            endurance: APIData[i].endurance,
            luck: APIData[i].luck,
            isBusy: isDogeBusy[APIData[i].id]
          };
          userDogesToSave[userDogesToSave.length] = newDoge;
        }
        console.log("userDogesToSave", userDogesToSave);

        setUserDoges(userDogesToSave);
      });
    } else {
      setUserDoges([]);
    }
  }

  async function getUserFrogz() {
    console.log("getUserFrogz");

    if (useReadBalance.data === undefined) return;
    if (useReadUserFrogz.data === undefined) return;


    let userOwnedCharacterCount = parseInt(useReadBalance.data);
    console.log("userOwnedCharacterCount", userOwnedCharacterCount);

    let tempUserCharacters = [];
    let dogesBusy = [];
    let isDogeBusy = [];

    let userDogesResponse = useReadUserFrogz.data;

    let dogeIds = userFrogz.ids;
    dogesBusy = userDogesResponse[1];

    for (let i = 0; i < dogeIds.length; i++) {
      tempUserCharacters[i] = { collection: selectedCollection, id: parseInt(dogeIds[i]) };
      isDogeBusy[tempUserCharacters[i].id] = dogesBusy[i];
    }

    if (tempUserCharacters.length > 0) {
      dogeBulkAPICall(tempUserCharacters).then((APIData) => {
        let userDogesToSave = [];

        for (let i = 0; i < APIData.length; i++) {
          let newDoge = {
            name: APIData[i].name,
            dogeId: APIData[i].id,
            image: APIData[i].image,
            speed: APIData[i].speed,
            endurance: APIData[i].endurance,
            luck: APIData[i].luck,
            isBusy: isDogeBusy[APIData[i].id]
          };
          userDogesToSave[userDogesToSave.length] = newDoge;
        }
        console.log("userDogesToSave", userDogesToSave);

        setUserDoges(userDogesToSave);
      });
    } else {
      setUserDoges([]);
    }
  }

  function selectDoge() {
    setSelectedCollection(COLLECTIONS.doge);
  }

  function selectCyberlete() {
    setSelectedCollection(COLLECTIONS.cyberlete);
  }

  function selectDisc() {
    setSelectedCollection(COLLECTIONS.disc);
  }

  function selectFrog() {
    setSelectedCollection(COLLECTIONS.smolFrogz);
  }

  return (
    <>
      <div className="em-dr-char-main">
        {
          (selectedCollection === null) ? (

            <div className="or-SelectNftMain">
              <span className="dr-charselhdr"> NFT Character Selection: </span>
              <div className="or-selNFTlist">
                <button className="dr-btn3 select-nft-collection" onClick={selectCyberlete}>
                  <img src={collectionCyberlete} alt="" />
                  <br />
                  Cyber Athlete
                </button>
                <button className="dr-btn3 select-nft-collection" onClick={selectDoge}>
                  <img src={collectionDoge} alt="" />
                  <br />
                  Moon Doge
                </button>
                <button className="dr-btn3 select-nft-collection" onClick={selectDisc}>
                  <img src={collectionDisc} alt="" />
                  <br />
                  DISC Drone
                </button>
                <button className="dr-btn3 select-nft-collection" onClick={selectFrog}>
                  <img src={collectionFrogz} alt="" />
                  <br />
                  Smol Frog
                </button>
              </div>
            </div>
          ) : (
            <>
              <span className="dr-charselhdr">Select NFT:</span>

              {
                (userDoges === null) ? (
                  <div className="message-wrapper">
                    <span>{getTranslation("loading")}</span>
                  </div>
                ) : (
                  (userDoges.length > 0) ? (
                    <div className="doge-wrapper row" id="userDoges">
                      {userDoges.map((doge) => (
                        <div className="col-6 col-sm-4 col-md-3" key={"doge-" + doge.dogeId}>
                          <div className={"doge-card"}>
                            {doge.name}<br />
                            <img src={doge.image} alt="" /><br />
                            <div className="trait"><span>{getTranslation("speed")}:</span> <span>{doge.speed}</span></div>
                            <div className="trait"><span>{getTranslation("endurance")}:</span> <span>{doge.endurance}</span></div>
                            <div className="trait"><span>{getTranslation("luck")}:</span> <span>{doge.luck}</span></div><br />
                            <button onClick={() => { selectDogeCallback(selectedCollection, doge.dogeId); }} disabled={doge.isBusy}>{doge.isBusy ? getTranslation("busy") : getTranslation("select")}</button>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="message-wrapper">
                      <span>You don't own any supported NFTs</span>
                    </div>
                  )
                )
              }
            </>
          )
        }

        <button className="dr-btn2" id="button-screen3" onClick={closeSelectionCallback}>{getTranslation("back")}</button>
      </div>
    </>
  )
}

