import { Link } from 'react-router-dom';
import { useState } from 'react';

import '../css/main.css';
import '../css/racing.css';

import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';


function FaqItem({ question, answer }) {
    const [expanded, setExpanded] = useState(false);

    const toggle = () => {
        setExpanded(!expanded);
    };

    return (

        <div className={expanded ? "faq-item-wrapper-2 expanded" : "faq-item-wrapper-2"} onClick={toggle}>
            <div className="faq-top-content">
                <h3 className="heading-size-h5 faq-title">
                    <strong>{question}</strong>
                    <br />
                </h3>
                <div className="faq-icon-2">
                    <div className="faq-icon-line-2 top"></div>
                    <div className="faq-icon-line-2 bottom"></div>
                </div>
            </div>
            <div className="faq-bottom-content">
                <div className="faq-spacer"></div>
                <p className="faq-paragraph-2">{answer}</p>
            </div>
        </div>
    );
}

export default function FAQ({ getTranslation }) {
    return (
        <>
            <div className="page-wrapper">

                <Navbar activePage="faq" getTranslation={getTranslation} />

                <div className="main-wrapper">

                    <div className="section medium default-padding-bottom ethosmeta wf-section">
                        <div className="container-default-2 w-container">
                            <div className="inner-container-600px center-content">
                                <div className="text-center">
                                    <h2>Open Racing FAQ</h2>
                                    <p className="mg-bottom-0px-2">Commonly asked Ethos/Open Racing Questions</p>
                                </div>
                            </div>
                            <div className="w-layout-grid grid-2-columns _1-column-tablet mg-top-48px faq">
                                <div className="w-layout-grid grid-1-column-2 top">
                                    <FaqItem
                                        question="What is Open Racing?"
                                        answer="Open Racing is a fun, competitive racing game incorporating Ethos NFTs. At Open Racing you can use your NFTs to compete against other NFTs in order to win prizes! The top 3 NFT in each race win rewards."
                                    />
                                    <FaqItem
                                        question="What does it mean to compete with an NFT?"
                                        answer="Competing with your NFT is a fun way to add utility to NFTs. In Open Racing’s case you’re entering your Ethos NFT into a race to compete against other people’s Ethos NFT. Each NFT has attributes that are factored into each race and used to determine winners."
                                    />
                                    <FaqItem
                                        question="Which NFT's are eligible to compete?"
                                        answer="Any Ethos NFT that follows the statistical guidelines for racing can be eligible to compete."
                                    />
                                    <FaqItem
                                        question="What else can I do with one of these NTFs?"
                                        answer="These NFTs are also playable avatars in the Ethos Metaverse. The Ethos is a hyper realistic space metaverse built on the UNREAL Engine, operating on the Avalanche Smart Chain. The Ethos will have a solar system in-game that mirrors our own while also having customized planets and moons mixed in. Currently you can explore Titan, the base planet, as an Ethos NFT. More activities will be made available over time including games to quest, achievements to earn, new planets to explore, new avatars to use, more customization for bases, and more ways to earn rewards."
                                    />
                                    <FaqItem
                                        question="How can I sell the NFT?"
                                        answer="NFTs can be sold on NFT marketplaces such as https://opensea.io/, https://nftrade.com/, and any marketplace that supports AVAX (Avalanche) NFTs"
                                    />
                                    <FaqItem
                                        question="Which NFT markets are supported?"
                                        answer="Any marketplace that supports AVAX NFTs will allow trading of Ethos NFTs."
                                    />
                                    <FaqItem
                                        question="How can a new Ethos NFT be added to compete?"
                                        answer={
                                            <ul>
                                                <li>Find the race you would like to join in the top section of the page under "OpenRacing Race Selection"</li>
                                                <li>Each race shows the entry fee, number of participants, and the planet the race takes place on.</li>
                                                <li>Click race details on the desired race. This will show race details, current participants and their stats.</li>
                                                <li>In the top right corner click “Enter Race”</li>
                                                <li>Select the collection of the NFT you would like to race.</li>
                                                <li>Click “Select”</li>
                                                <li>This will prompt a transaction. This is the entry fee plus gas to enter the race.</li>
                                                <li>Click confirm.</li>
                                                <li>Once the transaction is completed you will see your NFT in the race!</li>
                                                <li>Note: Races start after the final (ninth) NFT enters</li>
                                            </ul>
                                        }
                                    />
                                    <FaqItem
                                        question="Is Open Racing Fair?"
                                        answer="Yes. The winners of each race are determined using Chainlink's VRF (Verifiable Randomness Function) a 100% provably fair on chain Random Number Generator (RNG)."
                                    />
                                    <FaqItem
                                        question="How is Chainlink VRF utilized in Open Racing?"
                                        answer="Each NFT has statistics that are weighed into our competition system before a winner is determined using Chainlink VRF."
                                    />
                                    <FaqItem
                                        question="How do I find a list of the winners?"
                                        answer='Each race is catalogued on the Racing Page under the "Past Races" section. Each race you have entered an Ethos NFT in is outlined in green to easily find your most recent race.'
                                    />
                                </div>


                                <div className="w-layout-grid grid-1-column-2 top">
                                    
                                <FaqItem
                                        question="Is every race transaction stored On Chain?"
                                        answer="Yes every transaction interacting with the race contract is stored on the blockchain."
                                    />
                                    <FaqItem
                                        question="How can I be alerted when a competition starts or ends?"
                                        answer="We will be implementing a Telegram channel that will alert when races begin and finish. Until then simply check back on the racing page, or if you're lucky, you'll see an increase in AVAX in your wallet."
                                    />
                                    <FaqItem
                                        question="What if I decide not to continue after joining?"
                                        answer="After joining there is a “Leave Race” option below your Ethos NFT. You may leave as long as the race has not been filled."
                                    />
                                    <FaqItem
                                        question="If I leave a race early will I get my entry fee back?"
                                        answer="Yes, less gas fees. Remember this is a blockchain game so all transactions are recorded. As OpenRacing leverages the Avalanche Network, fees are minimal."
                                    />
                                    <FaqItem
                                        question="How do I request support?"
                                        answer="For support please click on the “Support link” on the bottom of the webpage. (this is tbd)"
                                    />
                                    <FaqItem
                                        question="What kind of rewards will be given to the winners?"
                                        answer={
                                            <>
                                                First place receives 50% of total entry fees, Second place receives 25% of total entry fees, and Third place receives 12.5% of total entry fees.
                                                <ul><li>
                                                    Example. $10 entry fee with nine doges means total entry fees of $90. 1st place wins $45, second place wins $22.5, and third receives $11.25.
                                                </li></ul>

                                            </>
                                        }
                                    />
                                    <FaqItem
                                        question="How do I verify I got the rewards when I win?"
                                        answer="You can search your own wallet address on snowtrace.io and on your internal transactions you will see all the receiving payouts for races."
                                    />
                                    <FaqItem
                                        question="Will you be adding other NFTs besides Doges to the game?"
                                        answer="Yes. First is Moon Doges, but we will be adding Cyberlete Athletes, and DISC drones to the competitions."
                                    />
                                    <FaqItem
                                        question="Can I enter more than one Ethos NFT in each race?"
                                        answer="Yes."
                                    />


                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <Footer getTranslation={getTranslation} />
            </div>

        </>
    );
}