import { APIS } from "../config";

export async function dogeBulkAPICall(inputNFTs) {

  if(inputNFTs === undefined) {
    return;
  }

  console.log("dogeBulkAPICall", inputNFTs);

  let aggregatedAPICalls = {};
  let collections = [];
  let collectionAddresses = [];

  for (let i = 0; i < inputNFTs.length; i++) {
    let collectionId = inputNFTs[i].collection.toLowerCase();
    let URI = APIS[collectionId];
    if(URI === undefined) {
      console.error("Unsupported collection");
      return [];
    }
    if(!collectionAddresses.includes(collectionId)) {
      collectionAddresses[collectionAddresses.length] = collectionId;
    }
    let ids = [];
    if (aggregatedAPICalls[URI] !== undefined) {
      ids = aggregatedAPICalls[URI];
    } else {
      collections[collections.length] = URI;
    }

    console.log(URI, collectionId, ids);

    ids[ids.length] = inputNFTs[i].id;
    aggregatedAPICalls[URI] = ids;
  }
  
  console.log("collections", collections);
  console.log("collectionAddresses", collectionAddresses);
  console.log("first loop done", aggregatedAPICalls);

  let apiData = [];

  for (let i = 0; i < collections.length; i++) {
    let APIURI = collections[i];
    let tokenIds = aggregatedAPICalls[APIURI];
    let collectionAddresss = collectionAddresses[i];

    let ids = encodeURIComponent(JSON.stringify(tokenIds));

    console.log("tokenIds", collectionAddresss, tokenIds);
    console.log("ids", ids);

    let response = await fetch(APIURI + ids);
    let dogeJson = await response.json(); //extract JSON from the http response

    for (let j = 0; j < dogeJson.length; j++) {
      let dogeApiData = {};
      dogeApiData.id = dogeJson[j].id;
      dogeApiData.collectionAddress = collectionAddresss;
      for (let k = 0; k < dogeJson[j].attributes.length; k++) {
        if (dogeJson[j].attributes[k].trait_type === "Speed") {
          dogeApiData.speed = dogeJson[j].attributes[k].value;
        } else if (dogeJson[j].attributes[k].trait_type === "Endurance") {
          dogeApiData.endurance = dogeJson[j].attributes[k].value;
        } else if (dogeJson[j].attributes[k].trait_type === "Luck") {
          dogeApiData.luck = dogeJson[j].attributes[k].value;
        }
      }
      for (let k = 0; k < dogeJson[j].debug.length; k++) {
        if (dogeJson[j].debug[k].type === "Raw Traits") {
          dogeApiData.encodedTraits = dogeJson[j].debug[k].value;
        }
      }
      dogeApiData.image = dogeJson[j].image;
      dogeApiData.name = dogeJson[j].name;
      apiData[apiData.length] = dogeApiData;
    }
  }
  console.log("apiData",apiData);

  return apiData;
}